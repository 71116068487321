<template>
    <div class="eCommerce-container">
        <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%" @sort-change="sortChange">
            <el-table-column label="排名" align="center" width="100px">
                <template slot-scope="scope">
                    <div class="sort-img" v-if="scope.row.ranking < 4">
                        <img v-if="scope.row.ranking === 1" :src="require('../../../../assets/images/student/num-01.png')" alt="">
                        <img v-else-if="scope.row.ranking === 2" :src="require('../../../../assets/images/student/num-02.png')" alt="">
                        <img v-else :src="require('../../../../assets/images/student/num-03.png')" alt="">
                    </div>
                    <div class="sort-num" v-else>{{scope.row.ranking | formatRank}}</div>
                </template>
            </el-table-column>
            <el-table-column label="商品信息">
                <template slot-scope="scope">
                    <div class="goods-info">
                        <div class="good-img" @click="toGoodDetail(scope.row.goods_id)">
                            <img :src="scope.row.good_images" alt="">
                        </div>
                        <div class="info-right text-overflow-2" @click="toGoodDetail(scope.row.goods_id)">{{scope.row.good_title}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="turnover_index" label="成交金额指数" align="center" sortable="custom" width="140px"></el-table-column>
            <el-table-column prop="transaction_volume_index" label="成交单量指数" align="center" sortable="custom" width="140px"></el-table-column>
            <el-table-column prop="visitor_index" label="访客指数" align="center" sortable="custom" width="140px"></el-table-column>
            <el-table-column prop="search_click_index" label="搜索点击量指数" align="center" sortable="custom" width="160px"></el-table-column>
            <el-table-column label="操作" align="center" width="160px">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="detailBtn(scope.row.id)">详情</el-link>
                    <el-link type="warning" v-if="purchase_goods_id === scope.row.id" :underline="false">已采购</el-link>
                    <el-link type="primary" v-else :underline="false" @click="purchaseBtn(scope.row.id)">采购</el-link>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex;flex-direction: column;align-items: center;">
                        <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                        <span>暂无数据</span>
                    </div>
                </div>
            </template>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0 30px" v-if="infoList.length > 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { stuOpStuOpDataCollectGoodsList, stuOpPurchaseGoods } from '@/utils/apis.js'
    export default {
        filters: {
            formatRank(val) {
                if (val < 10) {
                    return `0${val}`
                } else {
                    return val
                }
            }
        },
        data() {
            return {
                op_id: Number(this.$route.query.op_id) || null,
                course_id: Number(this.$route.query.course_id) || null,
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                order_field: void 0,
                order_type: void 0,
                purchase_goods_id: null, // 采购id
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            // 获取列表
            getList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                if (this.order_field) {
                    params.order_field = this.order_field
                }
                if (this.order_type) {
                    params.order_type = this.order_type
                }
                if (this.op_id) {
                    params.op_id = this.op_id
                    params.course_id = this.course_id
                }
                stuOpStuOpDataCollectGoodsList(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    this.purchase_goods_id = res.data.purchase_goods_id
                })
            },
            // 自定义表格数据排序
            sortChange(column, prop, order) {
                this.order_field = column.prop
                if (column.order === 'ascending') {
                    this.order_type = 'asc'
                } else if (column.order === 'descending') {
                    this.order_type = 'desc'
                } else {
                    this.order_type = void 0
                }
                this.listPages.currentPageNum = 1
                this.getList()
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            // 去商品详情
            toGoodDetail(id) {
                let routeDate = this.$router.resolve({
                    path: '/goods/detail',
                    query:{
                        g_id: id,
                        type: 1
                    }
                })
                window.open(routeDate.href, '_blank')
            },
            // 详情
            detailBtn(id) {
                if (this.op_id) {
                    let url = this.$router.resolve({
                        path: '/student/trainCenter/ECommerceDataCollect/productDetail',
                        query: {
                            id: id,
                            op_id: this.op_id,
                            course_id: this.course_id,
                        }
                    })
                    window.open(url.href);
                } else {
                    let url = this.$router.resolve({
                        path: '/student/examing/ECommerceCollectClean/productDetail',
                        query: {
                            id: id
                        }
                    })
                    window.open(url.href);
                }
            },
            // 采购
            purchaseBtn(id) {
                this.$confirm('是否采购该商品，只能采购一款商品，确定采购将无法采购其他商品', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                }).then(() => {
                    let params = {
                        id: id
                    }
                    if (this.op_id) {
                        params.op_id = this.op_id
                        params.course_id = this.course_id
                    }
                    stuOpPurchaseGoods(params).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
    .sort-num {
        display: inline-block;
        background: #E7F6EF;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        line-height: 24px;
        text-align: center;
    }
    .sort-img {
        display: inline-block;
        img {
            display: block;
        }
    }
    .goods-info {
        display: flex;
        align-items: center;
        .good-img {
            width: 80px;
            height: 80px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
                max-width: 100%;
            }
        }
        .info-right {
            width: 1%;
            flex: 1;
            margin-left: 10px;
            cursor: pointer;
        }
    }
</style>